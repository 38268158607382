import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import useGetConfEventContribution from '../customHooks/useGetConfEventContribution'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useApiUrlTranslation from './useApiUrlTranslation';
import { ViewColumnsIcon } from '@heroicons/react/24/outline';
import { EyeDropperIcon, EyeIcon } from '@heroicons/react/24/solid';

const IsState = (state) => {

    const color = state ? 'emerald' : 'red';

    return <div className="relative flex h-6 w-6 flex-none items-center justify-center">
        <div className={`h-1.5 w-1.5 rounded-full bg-${color}-500  ring-1 ring-${color}-400 group-hover:ring-${color}-800`}></div>
    </div>
}



const ConfEventContributionItem = ({ confEventContribution, prestaList }) => {

    const presta = prestaList && prestaList[0];


    return (

        <tr className="text-gray-400 divide-x divide-neutral-800">
            <td className="px-2">{confEventContribution.conf_event_contribution_order}</td>
            <td className="px-2">
                <div className="bg-neutral-800 max-w-fit rounded-full px-2 text-xs py-1">
                    {confEventContribution.conf_event_contribution_type.name}
                </div>
                {confEventContribution.name}
            </td>
            <td className="px-2">
                <div className="flex space-x-2 items-center">
                    <div> {confEventContribution.contact.flag && <img src={confEventContribution.contact.flag} alt="" className="w-6 h-6" />}
                    </div>
                    <div className="text-xs">{confEventContribution.contact.pays}</div>
                </div>
            </td>
            <td className="px-2"></td>
            <td className="px-2">
                <div className="flex items-center space-x-2">
                    <div><img src={confEventContribution.contact?.photos?.small} alt="" className="w-10 h-10 rounded-full" /></div>
                    <div>{confEventContribution.contact.nom} {confEventContribution.contact.prenom}</div>
                </div>
            </td>
            <td className="px-2">{confEventContribution.contact.societe}</td>
            <td>{IsState(!['', "0", 0, 'undefined', undefined].includes(confEventContribution.contact.organisme_video_url) || !['', "0", 0, 'undefined', undefined].includes(confEventContribution.contact.organisme_video_hosted))}</td>
            <td>


                <div className="flex">{IsState(presta && (presta.video_url || presta.video_hosted))}
                    {presta && presta.video_url && <a target="_blank" href={presta.video_url}> <EyeIcon className='w-6 text-emerald-500' />  </a>}
                    {presta && presta.video_hosted && <a target="_blank" href={`https://www.mlg-consulting.com/manager_cc/docs/archives/${presta.video_hosted}`}> <EyeIcon className='w-6 text-emerald-500' /></a>}
                </div>
            </td>
            <td>{IsState(presta && (presta.presta_visuel && presta.presta_visuel !== 'https://www.mlg-consulting.com/manager_cc/docs/archives//thumbs/no_picture_400x0.jpg'))}</td>

        </tr>

    )
}


const ConfEventContributions = () => {

    const { idConfEvent } = useParams();

    const [prestaList, setPrestaList] = useState(null);

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();


    const [dialogIsopen, setDialogIsOpen] = useState(false);

    const { confEventContributionList } = useGetConfEventContribution(
        { filter: `WHERE id_conf_event IN (SELECT id_conf_event FROM conf_event WHERE id_conf_event=${idConfEvent} )  ORDER BY conf_event_contribution_order` });




    const getPrestaList = async ({ conferencierList, confEventContributionList }) => {

        await fetch(`${apiUrlTranslation}?action=getPrestaList&params= WHERE id_contact IN (SELECT id_contact FROM conferenciers WHERE id_conf_event=${idConfEvent}) OR id_contact IN (SELECT id_contact FROM conf_event_contribution cet WHERE id_conf_event IN ( ${idConfEvent} ) AND cet.id_contact !=0)  
            `)
            .then(res => res.json())
            .then(res => {
                setPrestaList(res)
            })
    }


    useEffect(() => {
        getPrestaList({ confEventContributionList });
    }
        , [i18n.resolvedLanguage,]);


    return (
        <Layout>
            <div className='px-5'>
                {confEventContributionList && confEventContributionList[0] && <h1 className="py-5 text-2xl font-bold text-neutral-100"> {confEventContributionList[0].conf_event.conf_event_lang.cel_titre}</h1>}
                <table className="w-full">

                    <thead className="text-gray-500 uppercase">
                        <tr className="divide-x divide-neutral-800">
                            <th className="px-2">Order</th>
                            <th className="px-2">Type</th>
                            <th className="px-2">Pays</th>
                            <th className="px-2">Stat.</th>
                            <th className="px-2">Contact</th>
                            <th className="px-2">Société</th>
                            <th className="px-2">Cvid</th>
                            <th className="px-2">Pvid</th>
                            <th className="px-2">Pvis</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-neutral-900">
                        {confEventContributionList && confEventContributionList.map(confEventContribution =>
                            <ConfEventContributionItem
                                prestaList={prestaList && prestaList.filter(presta => presta.id_contact === confEventContribution.id_contact)}
                                confEventContribution={confEventContribution} />
                        )}
                    </tbody>
                </table>
            </div>
            <dialog open={true}>
                eeeeeeeeeee
            </dialog>
        </Layout>
    )
}

export default ConfEventContributions