import React, { useEffect, useState } from 'react'
import Layout from './Layout';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from './Loader';


import useApiUrlTranslation from './useApiUrlTranslation';

const PartenairesCycleLang = () => {

    const { idEvent, cycleLangType } = useParams();

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [partenaireList, setPartenaireList] = useState(null);

    const [contactCycleLangList, setContactCycleLangList] = useState(null);

    const getCycleLang = async ({ filter }) => {

        await fetch(`${apiUrlTranslation}?action=getContactCycleLang&filter=${filter}&yap`)
            .then(res => res.json())
            .then(res => setContactCycleLangList(res))
    }

    const getPartenaires = async ({ params }) => {

        await fetch(`${apiUrlTranslation}?action=getPartenaires&params=${params}`)
            .then(res => res.json())
            .then(res => {
                setPartenaireList(res);
                getCycleLang({ filter: ` id_contact IN (${res && res.map(partenaire => partenaire.contact.id_contact)}) GROUP BY id_cycle_lang` });

            })
    }

    useEffect(() => {
        getPartenaires({ params: `AND cf.id_event=${idEvent} GROUP BY c.id_contact` }).then(() => {

        });

    }, [i18n.resolvedLanguage]);

    return (
        <Layout>

            {!contactCycleLangList ? <Loader /> : <div className="flex mx-auto max-w-7xl flex-wrap items-center justify-center">
                {contactCycleLangList && contactCycleLangList
                    //filter by cycle_lang_type

                    // order by nom_cycle_lang
                    .sort((a, b) => a.cycle_lang.nom_cycle_lang.localeCompare(b.cycle_lang.nom_cycle_lang))
                    .filter(contactCycleLang => contactCycleLang.cycle_lang.cycle_lang_type == cycleLangType)
                    .map(contactCycleLang =>
                        <a
                            rel='noreferrer'
                            key={contactCycleLang.cycle_lang.id_cycle_lang}
                            target="_blank"
                            href={` /events/${idEvent}/collectifs/${contactCycleLang.cycle_lang.id_cycle_lang} `}>
                            <img src={contactCycleLang.cycle_lang.logo_better.large} className="w-80 h-80 m-2 " />
                        </a>
                    )}
            </div>}


        </Layout>
    )
}
export default PartenairesCycleLang;