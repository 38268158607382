import { useEffect, useState } from "react";
import useApiUrlTranslation from "../components/useApiUrlTranslation";

const useGetConfEvent = ({ idEvent, filter = '' }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [confEventList, setConfEventList] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const getConfEvent = async () => {

        await fetch(`${apiUrlTranslation}?action=getConfEvent&filter=${filter}&id_event=${idEvent}`)
            .then(res => res.json())
            .then(res => setConfEventList(res))
    }

    useEffect(() => {
        getConfEvent().then(() => {
            setIsLoading(false);
        });

    }, [i18n.resolvedLanguage])

    return { confEventList, isLoading }

}

export default useGetConfEvent;