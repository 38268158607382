import React, { useContext, useEffect } from 'react';

import RegistrationFormRow from './RegistrationFormRow'
import RegistrationFormSection from './RegistrationFormSection';
import { useTranslation, Trans } from 'react-i18next';
import RegistrationFormCountry from './RegistrationFormCountry';
import EventContext from '../contexts/EventContext';
import OrganismeVideoUrlChecker from './OrganismeVideoUrlChecker';
import OrganismeWebSiteChecker from './OrganismeWebSiteChecker';
import HTMLReactParser from 'html-react-parser';

const FORM_SECTION_ID = 7;


const RegistrationFormVideoPresentationSection = ({ contact, formulaire }) => {

    const { t, i18n } = useTranslation();

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    useEffect(() => {

    }, [i18n.resolvedLanguage, contact])

    return <RegistrationFormSection

        formSectionId={FORM_SECTION_ID}
        title={formulaire.video_presentation_section_title ?? t('form.sections.contact_and_organisation_section.video_section_title')}

        instructions={HTMLReactParser(formulaire.video_presentation_section_instructions != "" ? formulaire.video_presentation_section_instructions + "" : `<p>Collez ci dessous le lien d'une de vos videos (option 1) ou uploadez en une (Option 2).</p>
        <p>Si vous n'avez pas de video nous vous incitons à créer vous même un petit diaporama  à partir de photo qui illustrent votre savoir faire, vos clients ou votre solution que vous pourrez commenter lors du webcast.</p>
        <p>Si vous n'avez pas de photo ou de video nous illustrerons votre intervention avec votre site web dont l'adresse figure-ci-dessus.</p>`)}>


        <RegistrationFormRow
            required={false}
            textLabel="Priorité n° 1  :  coller le lien youtube de votre vidéo en ligne"
            inputType="text"
            name='organisme_video_url'
            placeholder=""
            helper={t('form.sections.contact_and_organisation_section.video_url_helper')}
            defaultValue={contact?.organisme_video_url} >

            <OrganismeVideoUrlChecker

                fieldName={'organisme_video_url'}
            />
        </RegistrationFormRow>

        <RegistrationFormRow
            required={false}
            inputType="file"
            name='organisme_video_hosted'
            placeholder="Priorité n° 2 : télécharger une vidéo au format mp4"
            helper=""
            accept={[
                //video format

                ".mp4",
                ".avi",
                ".mov",
                ".flv",
                ".wmv",
                ".webm",
                ".mkv",
            ]} >

        </RegistrationFormRow>

    </RegistrationFormSection>
}

export default RegistrationFormVideoPresentationSection;