import parse from 'html-react-parser';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RegistrationFormSection from './RegistrationFormSection';
import useApiUrlTranslation from './useApiUrlTranslation';
import { useEffect, useState } from 'react';
import { use } from 'i18next';

const FORM_SECTION_ID = 30;

const RegsistrationFormDroitImageSection = ({ contact }) => {

    const { t, i18n } = useTranslation();

    const { register, formState } = useFormContext();

    //console.log('errors', formState.errors);

    const [isDroitImageChecked, setIsDroitImageChecked] = useState();


    const handleChange = (event) => {

        const isChecked = event.target.checked ? 1 : 0;

        console.log('event', event.target.checked);
        setIsDroitImageChecked(isChecked);
    }

    useEffect(() => {

        if (contact) {
            setIsDroitImageChecked(contact.droit_image);
        }

    }, [contact]);

    return <RegistrationFormSection
        title={parse(t('form.sections.droits_image.section_title'))}
        instructions={parse(t('form.sections.droits_image.section_instructions'))}
        isError={formState.errors['droit_image']}
        formSectionId={FORM_SECTION_ID}
    >
        <label className="font-normal">

            <span className="p-2 text-danger">*</span>


            <input
                onChange={handleChange}
                defaultChecked={isDroitImageChecked}
                {...register("droit_image", { required: true })}
                placeholder="Droit à l'image"
                type="checkbox"
                id="droit_image"
                name="droit_image"
                value="1"
                className='mx-2' />
            {parse(t('form.sections.droits_image.section_text'))}
        </label>

    </RegistrationFormSection>
}

export default RegsistrationFormDroitImageSection;