import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RegistrationFormSection from './RegistrationFormSection';
import { RegistrationFormSupertitleSection } from './RegistrationFormStyleSection';
import RegistrationFormRow from './RegistrationFormRow';
import TimezoneSelectSection from './TimezoneSelectSection';

const RegistrationFormParticipationDistance = ({ formulaire,
    handleChange, setSelectedTimeZone, participeDistance }) => {

    const { register } = useFormContext();

    const { t, i18n } = useTranslation();

    useEffect(() => {

    }, [i18n.resolvedLanguage]);

    return <RegistrationFormSection

        formSectionId={23}
        title_={t("form.sections.mode_participation_section.section_title")}
        title={formulaire.participation_mode_section_title}
        supertitle={<RegistrationFormSupertitleSection> {formulaire.participation_mode_section_supertitle}</RegistrationFormSupertitleSection>}
        instructions={formulaire.participation_mode_section_instructions}
    >
        {/* <div className="flex flex-col space-y-3">
            <label className="form-check-label me-3 font-normal">
                <input
                    {...register("participation_a_distance", { required: true })}
                    type="radio"
                    id="participation_a_distance_1"
                    name="participation_a_distance"
                    value="1"
                    className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                /> {t("form.sections.mode_participation_section.participation_a_distance")}
            </label>
            <label className="form-check-label font-normal">
                <input
                    {...register("participation_a_distance", { required: true })}
                    type="radio"
                    id="participation_a_distance_0"
                    name="participation_a_distance"
                    value="0"
                    className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                /> {t("form.sections.mode_participation_section.participation_sur_place")}
            </label>
        </div> */}


        <RegistrationFormRow
            onChange={handleChange}
            inputType="radio"
            name='participation_a_distance'
            inputOptions={[{ label: t("form.sections.mode_participation_section.participation_a_distance"), value: 1 }, { label: t("form.sections.mode_participation_section.participation_sur_place"), value: 0 }]}
            placeholder={t("form.sections.mode_participation_section.selection_mode_participation")}
            defaultValue=""
            required={true} />

        {participeDistance == 1 && <div class="form-group row mb-1 py-2 w-full ">
            <label for="participation_a_distance" className="undefined col-sm-3 col-form-label"> {t("form.sections.mode_participation_section.selection_fuseau_horaire")}
            </label>
            <div class="col-sm-9">
                <TimezoneSelectSection
                    setSelectedTimeZone={setSelectedTimeZone} />
            </div>
        </div>}

    </RegistrationFormSection>
}

export default RegistrationFormParticipationDistance;