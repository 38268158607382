import React from 'react'
import useGetContactCycleLang from '../customHooks/useGetContactCycleLang';
import useGetContactStatutList from '../customHooks/usegetContactStatutList';
import HTMLReactParser from 'html-react-parser';
import RegistrationFormSection from './RegistrationFormSection';
import { Splide, SplideSlide } from "@splidejs/react-splide";


const PreferencesCycleLangItem = ({ contactCycleLangList }) => {


    if (!contactCycleLangList || contactCycleLangList.length == 0) return 'Pending...';

    return (

        <Splide
            options={{
                pagination: false,
                type: 'loop',
                autoplay: true,
                pauseOnHover: false,
                resetProgress: false,
                perPage: 1,
                arrows: false
            }}
            hasSliderWrapper
            hasAutoplayProgress
        > {
                contactCycleLangList && contactCycleLangList.map((contactCycleLang, index) => {
                    const cycleLang = contactCycleLang.cycle_lang
                    return <SplideSlide key={contactCycleLang.id_contact_cycle_lang}>
                        <img
                            src={cycleLang.logo_better.small} className="w-16 h-16"
                            alt='' />
                    </SplideSlide>
                })
            }
        </Splide>
    )
}

const PreferencesCard = (props) => {

    return <div className="border w-36">
        <div className="flex justify-center text-center bg-neutral-900 text-white px-3 py-1 uppercase text-xs h-18 items-center">{HTMLReactParser(props.title)}</div>
        <div className="flex p-2 items-center justify-center min-h-[100px]">
            {props.children}
        </div>
    </div>
}

const RegistrationFormContactPreferencesSection = ({ contact, formulaire }) => {

    const { contactStatutList } = useGetContactStatutList({ filter: ` WHERE id_contact=${contact?.id_contact}` });
    const { contactCycleLangList } = useGetContactCycleLang({ filter: `id_contact=${contact?.id_contact}` });

    if (!contact) return null;

    return (
        <RegistrationFormSection title="">

            {/* {JSON.stringify(contactCycleLangList)} */}
            {/* {JSON.stringify(contactStatutList)} */}

            <div className="flex flex-wrap lg:flex-row py-2 items-center">

                <PreferencesCard title="Ecosysteme">
                    <PreferencesCycleLangItem contactCycleLangList={contactCycleLangList && contactCycleLangList.filter(contactCycleLang => ['collectif_partenaire'].includes(contactCycleLang.cycle_lang.cycle_lang_type))} />
                </PreferencesCard>


                <PreferencesCard title="Statuts">

                    {contactStatutList && <div className="flex"> {

                        contactStatutList.map((contactStatut, index) => {

                            const attachedFileCollection = contactStatut.statut.attachedFileCollection;

                            const picto = attachedFileCollection && attachedFileCollection.find(attachedFile => attachedFile.id_attached_file_type.id_attached_file_type == 103);

                            return <div>
                                {picto && <img src={picto.file_name} className="w-16 h-16" />}
                            </div>
                        })}

                    </div>}

                </PreferencesCard>

                <PreferencesCard title="Zones d'actions">
                    <PreferencesCycleLangItem contactCycleLangList={contactCycleLangList && contactCycleLangList.filter(contactCycleLang => ['collectif_geographique'].includes(contactCycleLang.cycle_lang.cycle_lang_type))} />
                </PreferencesCard>

                <PreferencesCard title="Plans d'actions">
                    <PreferencesCycleLangItem contactCycleLangList={contactCycleLangList && contactCycleLangList.filter(contactCycleLang => ['collectif_planaction'].includes(contactCycleLang.cycle_lang.cycle_lang_type))} />
                </PreferencesCard>

                <PreferencesCard title="Thématiques">
                    <PreferencesCycleLangItem contactCycleLangList={contactCycleLangList && contactCycleLangList.filter(contactCycleLang => ['collectif_programme'].includes(contactCycleLang.cycle_lang.cycle_lang_type))} />
                </PreferencesCard>

            </div>

        </RegistrationFormSection>
    )
}

export default RegistrationFormContactPreferencesSection