import { useEffect, useState } from "react";
import useApiUrlTranslation from "../components/useApiUrlTranslation";

const useGetFormulaireList = ({ params }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [formulaireList, setFormulaireList] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const getFormulaireList = async () => {

        await fetch(`${apiUrlTranslation}?action=getFormulaireList&params=${params}`)
            .then(res => res.json())
            .then(res => setFormulaireList(res))
    }

    useEffect(() => {
        getFormulaireList().then(() => {
            setIsLoading(false);
        });

    }, [i18n.resolvedLanguage])

    return { formulaireList, isLoading }
}

export default useGetFormulaireList;