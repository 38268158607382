import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import { useParams } from "react-router-dom";
import { API_URL } from "../config";
import HTMLReactParser from "html-react-parser";
import Loader from "./Loader";
import useApiUrlTranslation from "./useApiUrlTranslation";
import RegistrationFormContactPreferencesSection from "./RegistrationFormContactPreferencesSection";
import { Button } from "@material-ui/core";
import { AnimatedButton } from "./Ui/Button";

const DemoDetails = (props) => {

  const { i18n, apiUrlTranslation } = useApiUrlTranslation()

  let { id_presta, idEvent } = useParams();

  const [demo, setDemo] = useState(null);

  const handleButtonClick = () => {
    window.location.href = `/events/${idEvent}/programm/${id_presta}/demo-details/`;
  };


  function extractYouTubeVideoId(url) {
    // Modèle de regex pour extraire l'ID de la vidéo YouTube
    var regex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\r\n]+)/;

    // Utiliser la regex pour extraire l'ID de la vidéo
    var match = url.match(regex);

    if (match && match[1]) {
      return match[1];
    } else {
      // Gérer le cas où l'URL n'est pas valide
      return null;
    }
  }


  useEffect(() => {

    const fetchGetDemo = async () => {

      await fetch(`${apiUrlTranslation}?action=getDemo&id_demo=${id_presta}`)
        .then((res) => res.json())
        .then((res) => setDemo(res));
    }

    fetchGetDemo();
  }, [i18n.resolvedLanguage]);



  if (!demo) {
    return false
  }
  let visuel = null;
  let logo = null;

  if (!demo.contact.logo.includes("no_picture")) {
    logo = demo.contact.logos.large;
    console.log(demo.contact.logo);
  }

  if (!demo.visuels.medium.includes("no_picture")) {
    visuel = demo.visuels.source;
  }

  return <Layout className="  mx-auto dark:text-neutral-300 px-5">



    <div className="flex flex-col lg:flex-row p-3 space-x-3 border-b border-neutral-800 my-5 pt-5 justify-between items-center">

      <div className="flex items-center bg-white p-2">
        {logo ? <img
          className="rounded max-h-60"
          alt="visuel de la démonstration"
          src={logo}

        /> : <div className="bg-neutral-900 p-3"> {demo.contact.societe}</div>}
      </div>

      <h1 className="text-4xl  lg:text-5xl align-right text-end dark:text-white">
        {demo.presta_nom}
      </h1>
    </div>



    <RegistrationFormContactPreferencesSection contact={demo.contact} />

    <h2 className="p-2 my-2">Descriptif de la démo</h2>
    <p className="text-start p-2 my-2">{HTMLReactParser(demo.presta_accroche ?? "")}</p>

    {demo.video_url && (
      <div>


        <h2 className="p-2 my-2 hidden">Vidéo de présentation</h2>
        <iframe
          className="rounded-2xl"
          width="100%"
          height="720"
          src={`https://www.youtube.com/embed/${extractYouTubeVideoId(demo.video_url)}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    )}

    {visuel ? <img src={visuel} alt="visuel de la démonstration" className="w-full h-100 object-cover mt-5 rounded-3xl" /> : ""}

    <h2 className="p-2 my-2">A propos de {demo.contact?.societe}</h2>

    <p className="p-2">{HTMLReactParser(demo.contact?.edito_long ?? "")}</p>



    {props.children}


    <div className="mx-auto max-w-xl my-5">
      <a
        href={`/events/${idEvent}/programm/${id_presta}/demo-details-private/`}
        className="max-w-fit  text-xl bg-pink-700 text-white rounded-full py-3 px-4 mx-4"

      >En savoir plus au sujet de {demo.contact?.societe}</a>

    </div>
  </Layout >


}

export default DemoDetails;


