import { useEffect, useState } from "react";
import useApiUrlTranslation from "../components/useApiUrlTranslation";

const useGetConfEventContribution = ({ filter }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [confEventContributionList, setConfEventContributionList] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const getConfEventContribution = async () => {

        setIsLoading(true);
        await fetch(`${apiUrlTranslation}?action=getConfEventContribution&filter=${filter}`)
            .then(res => res.json())
            .then(res => setConfEventContributionList(res))
    }

    useEffect(() => {

        getConfEventContribution().then(() => {
            setIsLoading(false);
        });

    }, [i18n.resolvedLanguage])

    return { confEventContributionList, isLoading }

}

export default useGetConfEventContribution;