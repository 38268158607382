import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useFormContext, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RegistrationFormSection from './RegistrationFormSection';
import { RegistrationFormSupertitleSection } from './RegistrationFormStyleSection';
import RegistrationFormRow from './RegistrationFormRow';
import RegistrationFormCycleLangListItemSection from './RegistrationFormCycleLangListItemSection';
import RegistrationFormCycleLangListSection from './RegistrationFormCycleLangListSection';
import useApiUrlTranslation from './useApiUrlTranslation';
import Loader from './Loader';
import useGetStatuts from '../customHooks/useGetStatuts';

const FORM_SECTION_ID = 39;

const atterissageOptions = [
    { value: 'Partenariats locaux stratégiques', label: 'Partenariats locaux stratégiques' },
    { value: 'Implantation via des incubateurs ou accélérateurs', label: 'Implantation via des incubateurs ou accélérateurs' },
    { value: 'Présence dans les salons et événements professionnels', label: 'Présence dans les salons et événements professionnels' },
    { value: 'Ouverture d’un bureau de représentation commerciale', label: 'Ouverture d’un bureau de représentation commerciale' },
    { value: 'Licensing ou modèles de franchises', label: 'Licensing ou modèles de franchises' },
    { value: 'Approche via les écosystèmes professionnels', label: 'Approche via les écosystèmes professionnels' },
    { value: 'Collaboration avec les agences gouvernementales', label: 'Collaboration   avec les agences gouvernementales' },
    { value: 'Pénétration via des plateformes numériques', label: 'Pénétration via des plateformes numériques' },
    { value: 'Programme pilote ou démonstration in situ', label: 'Programme pilote ou démonstration in situ' },
    { value: 'Rapprochement avec des multinationales', label: 'Rapprochement avec des multinationales' },
    { value: 'Rachat d’une entreprise locale', label: 'Rachat d’une entreprise locale' },
]

const autoevaluationOPtions = [
    { label: '<strong>Je pense que mon offre est unique ou très en avance par rapport au marché </strong> : à ma connaissance elle doit avoir un potentiel mondial d’accélération. Je recherche des landing partners, des financeurs et toutes ressources de confiance sur 5 continents le plus rapidement possible', value: '' },
    { label: '<strong>Je pense que ma solution est déjà très rentable ou performante et dispose de potentiel d’accélération important  </strong>: je recherche des partenaires pays pour distribuer, vendre ou générer des apports d’affaires', value: '' },
    { label: '<strong>Ma solution est rentable </strong>:  je cherche à étendre mon développement dans des pays prioritaires via des partenaires de confiance et un mode de développement export plus traditionnel  ', value: '' }
]

const niveauMAturiteOptions = [
    { label: "<strong>EXPORT LEVEL 1 -  EARLY STAGE EXPORTER </strong>: je démarre ma stratégie export et ne dispose que de peu de ressources pour sécuriser mon accélération. J’ai en priorité besoin du réseau de confiance de myGlobalVillage pour identifier des partenaires de confiance  et des ressources humaines,  techniques, juridiques commerciales  et financières à l'export.", value: '' },
    { label: "<strong>EXPORT LEVEL 2 :  	NEW ACTIVE  EXPORTER </strong> :  j’ai bien sécurisé mon développement dans mon pays d'origine. J'envisage d’ étendre mon activité dans  un ou plusieurs pays et de rechercher des relais de confiance de myGlobalVillage les ressources humaines,  techniques, juridiques, commerciales  et financières à l'export.", value: '' },
    { label: "<strong>EXPORT LEVEL 3 :  multi country player </strong>: je suis déjà présent dans plusieurs pays, je dispose des ressources juridiques, financières, commerciales pour accélérer mon développement mais souhaite bénéficier du réseau de confiance de my Global Village pour accroître plus encore  mon développement multi-pays.", value: '' },
]


const categorieList = [

    { label: "besoin_decollage_financier", libelle: "Financières" },
    { label: "besoin_decollage_humain_commercial", libelle: "Humaines et commerciales" },
    { label: "besoin_decollage_juridique", libelle: "Juridiques" },
    { label: "besoin_decollage_production", libelle: "Production/manufacturing" },
    { label: "besoin_decollage_strategique", libelle: "Stratégique" },
    { label: "besoin_decollage_technique", libelle: "Techniques" },
]

// A mon stade de développement export actuel (mono ou multi pays),  je précise les besoins de ma scale-up afin de préparer, sécuriser et financer mon décollage :
// stratégique : 
// pas de besoin :  j’ai clairement défini sa stratégie à l’export 
// je pourrais avoir besoin d’un entrepreneur spécialiste dans l'export de technologies certifiées myGlobalVillage
//  financières
// pas de besoin : je dispose des ressources financières nécessaires à mon accélération export
// je souhaites accéder au cercles des investisseurs mondial de myGlobalVillage 
// humaines et commerciales :
//  pas de besoin  : je dispose d’un responsable commercial export au sein de ma structure
// je pourrais avoir besoin de relais commerciaux ou de  spécialiste RH  certifiées myGlobalVillage 
// techniques, 
// pas de besoin  : je dispose de suffisamment de ressources de développement informatique et technique  au sein de ma structure
// je pourrais avoir besoin d’un tech partner spécialiste dans le développement offshore certifié  myGlobalVillage
// Juridiques
// pas de besoin  : je dispose des experts juridiques  au sein de ma structure pour adapter mon offre aux différents marchés visés (droit, homologation, sûreté etc.)
// je pourrais avoir besoin d’un partner juridique  spécialisé dans la tech  certifié  myGlobalVillage
// Production/manufacturing  
// Pas de besoin : ma ligne de production actuelle est conçue et adaptée à un accroissement potentiel des ventes liées aux marchés visés de grande taille
// je pourrais avoir besoin d’un partner manufacturing/production et supply chain spécialisé dans la tech  certifié  myGlobalVillage


const besoinOptions = [

    { label: "Pas de besoin : j'ai clairement défini sa stratégie à l'export", value: '', categorie: "stratégique" },
    { label: "Je pourrais avoir besoin d'un entrepreneur spécialiste dans l'export de technologies certifiées myGlobalVillage", value: '', categorie: "stratégique" },
    { label: "Pas de besoin : je dispose des ressources financières nécessaires à mon accélération export", value: '', categorie: "financières" },
    { label: "Je souhaites accéder au cercles des investisseurs mondial de myGlobalVillage", value: '', categorie: "financières" },
    { label: "Pas de besoin : je dispose d'un responsable commercial export au sein de ma structure", value: '', categorie: "humaines et commerciales" },
    { label: "Je pourrais avoir besoin de relais commerciaux ou de spécialiste RH  certifiées myGlobalVillage", value: '', categorie: "humaines et commerciales" },
    { label: "Pas de besoin : je dispose de suffisamment de ressources de développement informatique et technique  au sein de ma structure", value: '', categorie: "techniques" },
    { label: "Je pourrais avoir besoin d'un tech partner spécialiste dans le développement offshore certifié  myGlobalVillage", value: '', categorie: "techniques" },
    { label: "Pas de besoin : je dispose des experts juridiques  au sein de ma structure pour adapter mon offre aux différents marchés visés (droit, homologation, sûreté etc.)", value: '', categorie: "Juridiques" },
    { label: "Je pourrais avoir besoin d'un partner juridique spécialisé dans la tech certifié  myGlobalVillage", value: '', categorie: "Juridiques" },
    { label: "Pas de besoin : ma ligne de production actuelle est conçue et adaptée à un accroissement potentiel des ventes liées aux marchés visés de grande taille", value: '', categorie: "Production/manufacturing" },
    { label: "Je pourrais avoir besoin d'un partner manufacturing/production et supply chain spécialisé dans la tech  certifié  myGlobalVillage", value: '', categorie: "Production/manufacturing" },

]

const CyclelangSection = ({ cycleLangType, contact, formulaire }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()
    const [cycle_lang_list, setCycleLangList] = useState(null);


    const fetchCycleLang = async () => {

        let req_suite;

        if (cycleLangType === "all") {
            req_suite = ` IN('collectif_geographique', 'collectif_technologique','collectif_sectoriel','super_collectif','collectif_partenaire') `;
        } else if (cycleLangType == "collectif_geographique") {
            req_suite = ` = 'collectif_geographique' AND cycle_lang_categorie IN('continent')`;

        } else {
            req_suite = ` = '${cycleLangType}'`;
        }

        const url = `${apiUrlTranslation}?action=getCycleLang&filter=cycle_lang_type ${req_suite} AND cycle_lang_state NOT IN('hidden') AND  `;

        await fetch(url)
            .then((res) => res.json())
            .then((res) => {
                setCycleLangList(
                    res.filter(
                        (cycle_lang) => cycle_lang.cycle_lang_type === cycleLangType
                    )
                )
            })
    }

    useEffect(() => {

        fetchCycleLang();
    }, []);


    return <div className=" divide-y flex flex-col md:flex-row flex-wrap xl:max-h-[400px] ">
        {cycle_lang_list ? (
            cycle_lang_list
                .filter(cycle_lang => cycle_lang.cycle_lang_state == 'active')
                .map((cycle_lang) => (
                    <RegistrationFormCycleLangListItemSection
                        cycle_lang={cycle_lang}
                        formulaire={formulaire} />
                ))
        ) : (
            <Loader />
        )}
    </div>
}


const RegistrationFormStartupBusinessAccelerationSection = ({ contact, formulaire, cycleLangType }) => {

    const { t } = useApiUrlTranslation()


    const { statutList } = useGetStatuts({ filter: 'id_statut!=99999999999999' });

    return <>

        <RegistrationFormSection
            title={formulaire.startup_business_acceleration_section_title}
            formSectionId={FORM_SECTION_ID}
            supertitle={<RegistrationFormSupertitleSection> {formulaire.startup_business_acceleration_section_supertitle}</RegistrationFormSupertitleSection>}
            instructions={formulaire.startup_business_acceleration_section_instructions}>

            <RegistrationFormRow
                isRegister
                inputType="checkbox"
                name='______'
                placeholder={t('form.sections.startup_business_acceleration.potentiel_internationalisation')}
                required={false}
                inputOptions={statutList && statutList.filter(statut => statut.statut_categorie == "auto_eveluation").map(statut => ({ value: statut.id_statut, label: statut.statut_libelle }))}
            />
        </RegistrationFormSection>


        <RegistrationFormSection
            title={`I.2 ${t('form.sections.startup_business_acceleration.niveau_maturite_libelle')} `}>

            <RegistrationFormRow
                isRegister
                inputType="checkbox"
                name='______'
                placeholder={t('form.sections.startup_business_acceleration.niveau_maturite_libelle')}
                required={false}
                inputOptions={statutList && statutList.filter(statut => statut.statut_categorie == "niveau_maturite").map(statut => ({ value: statut.id_statut, label: statut.statut_libelle }))}
            />
        </RegistrationFormSection>

        <RegistrationFormSection
            supertitle={<RegistrationFormSupertitleSection>
                {t('form.sections.startup_business_acceleration.besoins_decollage_supertitle')}
            </RegistrationFormSupertitleSection>}
            instructions={t('form.sections.startup_business_acceleration.besoins_decollage_instructions')}>
            {/* 
            {besoinOptions.map(besoinOption => besoinOption.categorie)

                //deduplicate   by categorie
                .filter((value, index, self) => self.indexOf(value) === index)

                .map((categorie, index) => {

                    return <div>
                        {
                            <RegistrationFormRow
                                isRegister
                                inputType="radio"
                                name={`----${index}`}
                                placeholder={categorie}
                                required={false}
                                inputOptions={besoinOptions
                                    .filter(besoinOption => besoinOption.categorie === categorie)}
                            />
                        }</div>
                }
                )}

            <hr /> */}
            {categorieList && categorieList.map((categorie, index) => {

                return <div>
                    {
                        <RegistrationFormRow
                            isRegister
                            inputType="radio"
                            name={`----${index}`}
                            placeholder={categorie.libelle}
                            required={false}
                            inputOptions={statutList && statutList
                                .filter(statut => statut.statut_categorie === categorie.label)
                                .map(statut => ({ value: statut.id_statut, label: statut.statut_libelle }))}
                        />
                    }</div>
            }
            )}
        </RegistrationFormSection>

        <RegistrationFormSection
            supertitle={<RegistrationFormSupertitleSection>
                {t('form.sections.startup_business_acceleration.besoins_atterrissage_supertitle')}
            </RegistrationFormSupertitleSection>}
            title={t('form.sections.startup_business_acceleration.besoins_atterrissage_title')}
            instructions={t('form.sections.startup_business_acceleration.besoins_atterrissage_instructions')}>

            <RegistrationFormRow
                isRegister
                inputType="checkbox"
                name='______'
                placeholder={t('form.sections.startup_business_acceleration.besoins_atterrissage_libelle')}
                required={false}
                inputOptions={statutList && statutList.filter(statut => statut.statut_categorie == "mode_atterrissage").map(statut => ({ value: statut.id_statut, label: statut.statut_libelle }))}
            />
        </RegistrationFormSection>

        <RegistrationFormSection
            title={t('form.sections.startup_business_acceleration.pays_cibles_title')}
            instructions={t('form.sections.startup_business_acceleration.pays_cibles_instructions')}>

            <CyclelangSection
                cycleLangType={cycleLangType}
                contact={contact}
                formulaire={formulaire} />
        </RegistrationFormSection>

    </>
}

export default RegistrationFormStartupBusinessAccelerationSection;