import React from 'react'
import Layout from './Layout'
import { useParams } from "react-router-dom";
import useGetCycleLangByIdCycleLang from '../customHooks/useGetCycleLangByIdCycleLang';
import Loader from './Loader';
import HTMLReactParser from 'html-react-parser';
import useGetConfEvent from '../customHooks/useGetConfEvent';
import useGetContactCycleLang from '../customHooks/useGetContactCycleLang';
import useGetPartenaires from '../customHooks/useGetPartenaires';
import useGetFormulaireList from '../customHooks/UseGetFormulaireList';
import useGetCycleLang from '../customHooks/useGetCycleLang';
import Moment from 'react-moment';
import useApiUrlTranslation from './useApiUrlTranslation';

const CYCLELANG_FORM = [
    { idCycleLang: 3218, idFormulaire: 81 },
    { idCycleLang: 3217, idFormulaire: 90 },
    { idCycleLang: 3234, idFormulaire: 82 },
]

const FormulairesSection = ({ cycleLang }) => {

    const { idEvent } = useParams();

    const { formulaireList } = useGetFormulaireList({ params: ` WHERE id_formulaire IN(81,82,90)` });
    const { cycleLangList } = useGetCycleLang({ filter: `id_cycle_lang IN(3218, 3217, 3234) AND ` });


    return (
        <div className="">

            <h2 className="text-4xl font-bold my-3  " > Liens d'inscription aux temps forts de l'écosystème {cycleLang.nom_cycle_lang}</h2>


            <div className="divide-y divide-neutral-700 ">  {

                cycleLangList && CYCLELANG_FORM.map(cycleLangForm => {

                    const cercleIntelligenceCollective = cycleLangList && cycleLangList.find(cycleLang => cycleLang.id_cycle_lang == cycleLangForm.idCycleLang);
                    const formulaire = formulaireList && formulaireList.find(formulaire => formulaire.id_formulaire == cycleLangForm.idFormulaire);

                    if (!cercleIntelligenceCollective || !formulaire) return null;

                    return <div className=" hover:bg-neutral-900 flex flex-col space-y-3 py-4 border-b-neutral-600">

                        <p className="text-lg font-bold ">   {cercleIntelligenceCollective.nom_cycle_lang}</p>
                        <p className="mt-0 text-gray-400 text-sm">   {HTMLReactParser(cercleIntelligenceCollective.cycle_lang_descriptif)}</p>

                        <p className="mt-3">
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={`https://www.myglobalvillage.com/events/${idEvent}/signup/${formulaire.id_formulaire}/?form-name=${formulaire.route}&formisvisible=1&id_formulaire=${formulaire.id_formulaire}&lng=en&id_cycle_lang_preselection_list=${cycleLang.id_cycle_lang},${cercleIntelligenceCollective.id_cycle_lang}`}
                                className="flex bg-pink-700 text-white  px-4 rounded-full py-2 items-center max-w-fit">

                                <img src={cercleIntelligenceCollective.logo_better.tiny} className="w-6 h-6 " alt="" />

                                Vous souhaitez rejoindre  {cercleIntelligenceCollective.nom_cycle_lang} - {cycleLang.nom_cycle_lang} </a>
                        </p>
                    </div>
                })
            }
            </div>
        </div>
    )
}

const ContactsSection = ({ partenaireList, confEvent }) => {

    return (<div className="flex -space-x-2">

        {partenaireList && partenaireList
            //filter by conf_event.id_conf_event = partenaire.conf_event.id_conf_event
            .filter(partenaire => partenaire.conf_event.id_conf_event == confEvent.id_conf_event)

            .map(partenaire => {

                const contact = partenaire.contact;
                return <div className="flex items-center space-x-2">
                    <img
                        src={contact.photos.tiny}
                        className="w-12 h-12 rounded-full"
                        alt={`${contact.prenom} ${contact.nom}`}
                        title={`${contact.prenom} ${contact.nom}`} />
                </div>
            })}
    </div>)
}

const ConfEventSection = () => {

    const { idCycleLang, idEvent } = useParams();

    const { contactCycleLangList, isLoading } = useGetContactCycleLang({ filter: `  id_cycle_lang=${idCycleLang}` });

    const { cycleLang, setCycleLang } = useGetCycleLangByIdCycleLang({ idCycleLang });

    const { partenaireList } = useGetPartenaires({ params: `AND cf.id_event=${idEvent} AND id_conf_event NOT IN("",0)` })

    const { confEventList } = useGetConfEvent({ idEvent: idEvent, filter_: `AND id_conf_event IN()}` });

    const { i18n } = useApiUrlTranslation()

    if (!partenaireList) return <Loader />

    return (

        <div>
            <h2 className="text-4xl font-bold my-3">Grille de programme : contribution de l'écosystème {cycleLang.nom_cycle_lang}</h2>

            <div className="pb-5">Engagé à l'année au décollage et à l'atterrissage des solutions innovantes de + de 30 pays, retrouvez dans la grilles de programme ci-dessous les contributions de l'écosystème {cycleLang.nom_cycle_lang} à cet événement.</div>
            {/* 
            {JSON.stringify(partenaireList && partenaireList
                .filter(partenaire => contactCycleLangList.map(contactCycleLang => contactCycleLang.contact.id_contact).includes(partenaire.contact.id_contact))
                .map(partenaire => partenaire.id_conf_event))} */}
            {/* {JSON.stringify(contactCycleLangList)} */}
            {/* {JSON.stringify(confEventList)} */}

            {/* <div className="divide-y">
                {confEventList && confEventList.map(confEvent => <div className="flex flex-col space-y-3">
                    {confEvent.conf_event_lang.cel_titre} </div>)}
            </div> */}



            <div className=" divide-neutral-800 divide-y rounded-2xl bg-neutral-900">
                {partenaireList && partenaireList
                    .filter(partenaire =>
                        contactCycleLangList
                            //    .filter(contactCycleLang => contactCycleLang.id_cycle_lang == idCycleLang)
                            .map(contactCycleLang => contactCycleLang.contact.id_contact).includes(partenaire.contact.id_contact))
                    //order by   partenaire.conf_event.conf_event_date_local
                    //deduplicate by partenaire.conf_event.id_conf_event
                    .filter((partenaire, index, self) =>

                        index === self.findIndex((t) => (
                            t.conf_event.id_conf_event === partenaire.conf_event.id_conf_event
                        ))
                    )
                    .sort((a, b) => new Date(a.conf_event.conf_event_date_local) - new Date(b.conf_event.conf_event_date_local))

                    .map(partenaire => {

                        const confEvent = partenaire.conf_event;

                        return <div className="flex space-x-3 py-2  hover:bg-neutral-800 mx-2 ">

                            <div className="flex flex-col justify-center items-center basis-2/12">
                                <span className="text-neutral-500">Local hour</span>
                                <span className="text-pink-500">
                                    <Moment
                                        locale={i18n.resolvedLanguage} format="LL"
                                        format_="dddd D MMMM YYYY">{confEvent.conf_event_date_local}</Moment> -  {confEvent.heure_debut_local} {confEvent.heure_fin_local}</span>
                            </div>
                            <div className="flex items-center basis-6/12">
                                {confEvent.conf_event_lang.cel_titre} </div>
                            <div className="flex items-center space-x-2 basis-4/12">
                                <ContactsSection
                                    confEvent={confEvent}
                                    partenaireList={partenaireList} />
                            </div>
                        </div>
                    }
                    )}
            </div>
        </div >
    )
}

const CycleLang = () => {

    const { idCycleLang } = useParams();

    const { cycleLang, isLoading } = useGetCycleLangByIdCycleLang({ idCycleLang });

    if (isLoading) return <Loader />

    if (!cycleLang) return <div> </div>
    return (
        <Layout darkmode className="px-4">
            <div className="">
                <div className="flex items-center ">
                    <img
                        alt="Logo collecti"
                        width="10%"
                        className="rounded-2xl"
                        src={cycleLang.logo_better_url}
                    />
                    <p className="py-3 m-3 text-6xl font-extrabold animatedgradient">{cycleLang.nom_cycle_lang}</p>
                </div>

                <h2 className="text-4xl font-bold my-3">Ecosystème {cycleLang.nom_cycle_lang} mobilisé sur l'événement :</h2>
                <p className="my-3   text-lg">{HTMLReactParser(cycleLang.cycle_lang_descriptif)}</p>

            </div>

            <FormulairesSection cycleLang={cycleLang} />
            <ConfEventSection />
        </Layout>
    )
}

export default CycleLang