import HTMLReactParser from 'html-react-parser';
import React, { createRef, useState } from 'react';
import { useFormContext } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { capitalize } from '../utils';
import FormInputTextareaRichEditor from './FormInputTextareaRichEditor';
import FormInputTextareaRichEditorWithReactHookForm from './FormInputTextareaRichEditorWithReactHookForm';

const RegistrationFormInput = ({
    inputType,
    onChange,
    name,
    required,
    placeholder,
    value,
    defaultValue,
    isRegister,
    inputOptions,
    accept = [".jpg", ".jpeg", ".png", ".pdf"],
    maxLength
}) => {

    const { t, i18n } = useTranslation();

    const { register } = useFormContext();

    const [fileName, setFileName] = useState('');

    const handleChangeFileName = (event) => {
        setFileName(event.target.files[0].name);
    };


    switch (inputType) {

        case 'select': return <div className="form-checked" key={name}>


            <select className='w-full px-2 py-2 rounded' {...register(name, { required: required })}>
                {inputOptions && inputOptions.map((option, index) =>
                    <option
                        key={index}
                        value={option.value}> {option.label}</option>
                )}
            </select>
        </div>

        case 'radio': return <div className="form-check" key={name}>
            {inputOptions && inputOptions.map((option, index) =>

                <div className="form-check" key={index}>

                    <label className="form-check-label font-normal">
                        <input
                            {...register(name,
                                {
                                    required: required,
                                    onChange: (event) => {
                                        if (onChange) {
                                            onChange(event);
                                        }
                                    },
                                })}
                            type="radio"
                            className="form-check-input"
                            value={option.value} />
                        {HTMLReactParser(option.label ?? "")}
                    </label>
                </div>
            )}
        </div>

        case 'checkbox': return <div className="form-check" key={name}>
            {inputOptions && inputOptions.map((option, index) =>

                <div className="form-check" key={index}>

                    <label className="form-check-label font-normal">
                        <input
                            {...register(name,
                                {
                                    required: required,
                                    onChange: (event) => {
                                        if (onChange) {
                                            onChange(event);
                                        }
                                    },
                                })}
                            type="checkbox"
                            className="form-check-input"
                            value={option.value} />
                        {HTMLReactParser(option.label ?? "")}
                    </label>
                </div>
            )}
        </div>


        // case 'checkbox': return <div className="form-check">
        //     <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
        //     <label className="form-check-label" htmlFor="flexCheckDefault">
        //         {placeholder}
        //     </label>
        // </div>

        //case 'textarea': return <textarea  {...register(name, { required: required })} type="text" className="form-control" placeholder={placeholder ?? name}></textarea>

        //case 'textarea': return <FormInputTextareaRichEditor register={{ ...register(name, { required: required }) }} />

        case 'textarea': return isRegister ? <FormInputTextareaRichEditorWithReactHookForm
            onChange={onChange}
            name={name}
            fieldName={name}
            required={required}
            fieldValue={defaultValue} /> : <FormInputTextareaRichEditor
            onChange={onChange}
            name={name}
            fieldName={name}
            isRegister={isRegister} />


        case 'file____333': return <> <label for="files" className="form-label border rounded pe-5 ps-3 py-2 bg-light">{t('form.parcourir')}...</label>
            <input id="files"
                style={{ visibility: "hidden" }}
                type="file"
                {...register(name, { required: required })}
                className="form-control "
                accept=".jpg, .jpeg, .png"
                placeholder={placeholder ?? name} /></>

        case 'file': return <div className="flex items-center">
            <div className="flex-shrink-0">
                <input
                    id={`input-file-${name}`}
                    {...register(name, { required: required })}
                    type="file"
                    className="form-control "
                    accept={accept.map(item => `${item}`).join(',')}
                    placeholder={HTMLReactParser(placeholder ?? name ?? "")}
                    onChange={handleChangeFileName}
                />
                <label htmlFor={`input-file-${name}`} className="d-none cursor-pointer bg-gray-300 hover:bg-gray-400 font-normal py-2 px-4 rounded">
                    {t('form.parcourir')}
                </label>
                {fileName && <span className="ml-2">{fileName}</span>}
            </div>
            <div className="ml-4">

                {defaultValue && <img src={defaultValue} className="h-32 w-32 rounded-2xl  border-3  object-contain border-gray-900 " />}
            </div>
        </div>



        case 'hidden': return <input  {...register(name, {
            required: required,
            value: defaultValue
        })}

            type="hidden"
            className="form-control rounded-pill"
            id={name}

            placeholder={placeholder ?? name} />

        case 'date': return <input  {...register(name, {
            required: required,
            value: defaultValue
        })}

            type="date"
            className="form-control rounded-pill"
            id={name}

            placeholder={placeholder ?? name} />

        case 'number': return <input  {...register(name, {
            required: required,
            value: defaultValue
        })}

            type="number"
            className="form-control rounded-pill"
            id={name}

            placeholder={placeholder ?? name} />


        default: return <> <input  {...register(name, {
            required: required,
            value: defaultValue
        })}
            onChange={onChange}
            maxLength={maxLength}
            type="text"
            className="form-control rounded-pill"
            id={name}
            placeholder={placeholder ?? name} /></>
    }
}

export default RegistrationFormInput;