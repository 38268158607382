import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import DemoDetails from "./DemoDetails";
import { useEffect, useState } from "react";
import useApiUrlTranslation from "./useApiUrlTranslation";


const DemoDetailsPrivate = (props) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    //let { routeName } = useParams();
    let { id_presta, } = useParams();

    //get route name
    //let { routeName } = useParams();

    const [demo, setDemo] = useState(null);

    useEffect(() => {

        const fetchGetDemo = async () => {

            await fetch(`${apiUrlTranslation}?action=getDemo&id_demo=${id_presta}`)
                .then((res) => res.json())
                .then((res) => setDemo(res));
        }

        fetchGetDemo();
    }, [i18n.resolvedLanguage]);


    if (!demo) {
        return false
    }

    return <div>


        <DemoDetails>

            <h2 className="p-2 my-2">Contact</h2>

            <div className="flex p-2 fw-bold items-center space-x-4">

                <div className="flex space-x-2">
                    <img alt="photo du contact" src={demo.contact?.photos.small} className="rounded-full w-24 h-24 object-cover" />
                </div>
                <div>
                    {demo.contact?.prenom} {demo.contact?.nom} -{" "}
                    {demo.contact?.fonction}
                    <p> {demo.contact?.port} </p>
                    {demo.contact?.mail}
                </div>

            </div>



            <h2 className="mt-5">{t('form.sections.techfund_raiser.recherche_landing_partners')} </h2>
            <h2 className="mt-5">{t('form.sections.techfund_raiser.levee_fonds')}</h2>


            {
                //   { field: 'distribution_produit_disponible', label: 'form.sections.techfund_raiser.distribution_produit_disponible' },
                //   { field: 'distribution_produit_conforme', label: 'form.sections.techfund_raiser.distribution_produit_conforme' },
                //   { field: 'distribution_ressources_disponibles', label: 'form.sections.techfund_raiser.distribution_ressources_disponibles' },
                //   { field: 'distribution_juridique_is_ok', label: 'form.sections.techfund_raiser.distribution_juridique_is_ok' },
                //  { field: 'global_funding_product', label: 'form.sections.techfund_raiser.product' },


            }
            <div className="mt-3">
                {[
                    { field: 'global_funding_tour_financement', label: 'form.sections.techfund_raiser.montant_capital_recherche' },
                    { field: 'global_funding_montant', label: 'form.sections.techfund_raiser.fonds_collectes' },
                    { field: 'deck_presentation', label: 'form.sections.techfund_raiser.telecharger_dossier' },
                    { field: 'global_funding_traction', label: 'form.sections.techfund_raiser.traction' },
                    { field: 'global_funding_probleme_type', label: 'form.sections.techfund_raiser.product_solve' },
                    { field: 'global_funding_montant_capital_recherche', label: 'form.sections.techfund_raiser.montant_capital_recherche' },
                    { field: 'fund_raising_taille_marche', label: 'form.sections.techfund_raiser.fund_raising_taille_marche' },
                    { field: 'fund_raising_valorisation_entreprise', label: 'form.sections.techfund_raiser.fund_raising_valorisation_entreprise' },
                    { field: 'fund_raising_souhaite_accompagnement_levee_fond', label: 'form.sections.techfund_raiser.fund_raising_souhaite_accompagnement_levee_fond' },

                ].map((item, index) => {

                    return <div key={index}>
                        <div className="mt-2 border-t border-white/10">
                            <dl className="divide-y divide-white/10">
                                <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm/6 font-medium text-white">{t(item.label)}</dt>
                                    <dd className="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{demo.contact[item.field]}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                }
                )}


            </div>
        </DemoDetails>
    </div >

}

export default DemoDetailsPrivate;