import { useEffect, useState } from "react";
import useApiUrlTranslation from "./useApiUrlTranslation";

const TimezoneSelectSection = ({ setSelectedTimeZone }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [timeZones, setTimeZones] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');

    const handleChange = (event) => {
        setSelectedTimeZone(event.target.value);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredTimeZones = timeZones && timeZones.filter(timeZone =>
        timeZone.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const getTimeZones = async () => {

        await fetch(`${apiUrlTranslation}?action=getTimeZones`)
            .then(res => res.json())
            .then(res =>
                setTimeZones(res)
            )
    }

    useEffect(() => {

        getTimeZones();

    }, [])

    return <div>
        <div className=" ">

            <input
                type="text"
                id="timezone-search"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Filter..."
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />

            <select
                id="timezone-select"
                name="timezone"
                onClick={handleChange}
                className=" block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
                <option value="">Select a time zone</option>
                {timeZones && filteredTimeZones.map((timeZone, index) => (
                    <option key={index} value={timeZone}>
                        {timeZone}
                    </option>
                ))}
            </select>
        </div>
    </div>
}

export default TimezoneSelectSection;