import React, { useEffect, useContext } from 'react';
import EventContext from '../contexts/EventContext';
import styled from 'styled-components'
import { AnimatedButton } from './Ui/Button';

const EventStar = ({ event }) => {

    const eventContext = useContext(EventContext);

    const NextEventContainer = styled.div`
    opacity: 0.9;
    background-clip: padding-box,border-box;
    border: 6px solid transparent;
    /*border-image: linear-gradient(to left top,#f5255d 0,#f7d700);*/
    border-image: linear-gradient(to left top,#000 0,#5b1643);
    border-image-slice: 1;
    background-clip: padding-box,border-box;
    transition: all .3s ease;
    border-radius: 20px;
    color: #fff;
    background: rgba(0,0,0,.5);
    padding:10px;
    margin-top:30px;
    cursor:pointer;

background-image: linear-gradient(to top left,#000 0,#5b1643);
&:hover {
        background-image: linear-gradient(to top left,#f5255d 0,#5b1643);
}
`;

    useEffect(() => {
        console.log(event)
    }, [])

    return (
        <NextEventContainer onClick={() => window.open(event.id_event == 1656 ? 'https://www.myglobalvillage.com/ces/' : `https://www.myglobalvillage.com/events/${event.id_event}`)}>
            <div className="flex space-x-4">
                <div className="basis-2/12">
                    <img src={`//www.mlg-consulting.com/manager_cc/events/img_uploaded/${event.logo}`} alt="" />
                </div>
                <div className="basis-8/12">
                    <p className="hidden" style={{ fontWeight: 800, fontSize: "30px" }}>A l'affiche</p>
                    <p className="lead mb-0 display-5">{event.nom}</p>
                    <p className="lead mb-3 display-7 pt-3"> <i className="far fa-calendar-alt"></i> {event.precision_date} | <i className="fas fa-map-marker-alt"></i>  {event.lieu.lieu_nom} - {event.lieu.lieu_ville}</p>
                    <p className="pt-5">

                        <AnimatedButton

                            className="max-w-fit"
                            btnText=" Accéder au site officiel de l'événement pour découvrir le programme" />

                    </p>
                </div>
            </div>
        </NextEventContainer >
    )
}

export default EventStar