import HTMLReactParser from "html-react-parser";
import { CheckData } from "../utils";
import useApiUrlTranslation from "./useApiUrlTranslation";

const ContactShow = ({ contact, setShowEditSection }) => {

    const { t } = useApiUrlTranslation();

    if (!contact) return null;

    return <div className="flex flex-row">

        <div className="pr-5 basis-1/6">
            <img className=" w-60 h-60 rounded-full object-cover" src={contact.photos.large} />
        </div>
        <div className="basis-5/6">
            <div className="divide-y divide-gray-100">
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name={t('form.sections.contact_section.prenom')} value={contact.prenom} />
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name={t('form.sections.contact_section.nom')} value={contact.nom} />
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name={t('form.sections.contact_section.fonction')} value={contact.fonction} />
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="E-mail" value={contact.mail} />
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="mobile" value={contact.port} />

                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="linkedin" value={contact.sn_linkedin} />
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="X (Twitter) ID" value={contact.sn_twitter} />
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="Bio" value={contact.edito_court ? HTMLReactParser(contact.edito_court) : ""} />
            </div>
        </div>
    </div>
}

export default ContactShow;